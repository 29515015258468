window.dmx = window.dmx || {};

dmx.__components = {};
dmx.__attributes = {
    before: {},
    mount: {},
    mounted: {}
};
dmx.__formatters = {
    boolean: {},
    global: {},
    string: {},
    number: {},
    object: {},
    array: {}
};
dmx.__adapters = {};
dmx.__actions = {};

// default options
dmx.config = {
    mapping: {
        'form': 'form',
        'button, input[type=button], input[type=submit], input[type=reset]': 'button',
        'input[type=radio]': 'radio',
        'input[type=checkbox]': 'checkbox',
        'input[type=file][multiple]': 'input-file-multiple',
        'input[type=file]': 'input-file',
        //'input[type=number]': 'input-number',
        'input': 'input',
        'textarea': 'textarea',
        'select[multiple]': 'select-multiple',
        'select': 'select',
        '.checkbox-group': 'checkbox-group',
        '.radio-group': 'radio-group'
    }
};

dmx.reIgnoreElement = /^(script|style)$/i;
dmx.rePrefixed = /^dmx-/i;
dmx.reExpression = /\{\{(.+?)\}\}/;
dmx.reExpressionReplace = /\{\{(.+?)\}\}/g;
dmx.reToggleAttribute = /^(checked|selected|disabled|required|hidden|async|autofocus|autoplay|default|defer|multiple|muted|novalidate|open|readonly|reversed|scoped)$/i;
dmx.reDashAlpha = /-([a-z])/g;
dmx.reUppercase = /[A-Z]/g;

dmx.appConnect = function(node, cb) {
    if (dmx.app) {
        return alert('App already running!');
    }

    node = node || document.documentElement;

    window.onpopstate = function() {
        dmx.requestUpdate();
    };

    window.onhashchange = function() {
        dmx.requestUpdate();
    };

    var App = dmx.Component('app');

    dmx.app = new App(node, dmx.global);
    dmx.app.$update();
    if (cb) cb();
};

document.documentElement.style.visibility = 'hidden';

document.addEventListener('DOMContentLoaded', function() {
    var appNode = document.querySelector(':root[dmx-app], [dmx-app], :root[is="dmx-app"], [is="dmx-app"]');
    if (appNode) {
        dmx.appConnect(appNode, function() {
            document.documentElement.style.visibility = '';
            appNode.removeAttribute('dmx-app');
        });
    } else {
        document.documentElement.style.visibility = '';
        console.warn('No APP root found!');
    }
});

dmx.useHistory = window.history && window.history.pushState;

dmx.extend = function () {
    // Variables
    var extended = {};
    var deep = false;
    var i = 0;
    var length = arguments.length;

    // Check if a deep merge
    if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
        deep = arguments[0];
        i++;
    }

    // Merge the object into the extended object
    var merge = function (obj) {
        for ( var prop in obj ) {
            if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
                // If deep merge and property is an object, merge properties
                if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
                    extended[prop] = dmx.extend( true, extended[prop], obj[prop] );
                } else {
                    if (obj[prop] != null) {
                        extended[prop] = obj[prop];
                    }
                }
            }
        }
    };

    // Loop through each object and conduct a merge
    for ( ; i < length; i++ ) {
        var obj = arguments[i];
        merge(obj);
    }

    return extended;
};

dmx.noop = function() {};

dmx.isset = function(val) {
    return v !== undefined;
};

dmx.equal = function(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (typeof a !== typeof b) return false;
    if (typeof a == 'number' && isNaN(a) && typeof b == 'number' && isNaN(b)) return true;
    
    if (Array.isArray(a)) {
        if (a.length !== b.length) return false;
        if (a.length === 0) return true;
        for (var i in a) {
            if (!dmx.equal(a[i], b[i])) return false;
        }
        return true;
    } else if (typeof a == 'object') {
        for (var key in a) {
            if (!dmx.equal(a[key], b[key])) return false;
        }

        for (var key in b) {
            if (!dmx.equal(a[key], b[key])) return false;
        }

        return true;
    }

    return false;
};

dmx.clone = function(o) {
    if (!o) return o;

    if (typeof o == 'object') {
        var clone = Array.isArray(o) ? [] : {};
        for (var key in o) {
            clone[key] = dmx.clone(o[key]);
        }
        return clone;
    }

    return o; // && JSON.parse(JSON.stringify(o));
};

dmx.array = function(arr) {
    if (arr == null) return [];
    return Array.prototype.slice.call(arr);
};

dmx.hashCode = function(o) {
    if (o == null) return 0;
    var str = JSON.stringify(o);
    var i, hash = 0;
    for (i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash = hash & hash;
    }
    return Math.abs(hash);
};

dmx.randomizer = function(seed) {
    seed = +seed || 0;
    return function() {
        seed = (seed * 9301 + 49297) % 233280;
        return seed / 233280;
    };
};

dmx.repeatItems = function(repeat) {
    var items = [];

    if (repeat) {
        if (typeof repeat == 'object') {
            var i = 0;

            for (var key in repeat) {
                var item = dmx.clone(repeat[key]);
                items.push(Object.assign({
                    $key: key,
                    $index: i,
                    $value: item
                }, item));
                i++;
            }
        } else if (typeof repeat == 'number') {
            for (var n = 0; n < repeat; n++) {
                items.push({
                    $key: String(n),
                    $index: n,
                    $value: n + 1
                });
            }
        }
    }

    return items;
};

dmx.escapeRegExp = function(val) {
    // https://github.com/benjamingr/RegExp.escape
    return val.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
};

dmx.validate = function(node) {
    return node.checkValidity();
};

dmx.validateReset = function(node) {
    // reset validation?
};

if (window.setImmediate) {
    dmx.nextTick = function(fn, context) {
        return window.setImmediate(fn.bind(context));
    };
} else if (window.postMessage) {
    (function() {
        var queue = [];

        window.addEventListener('message', function(event) {
            if (event.source === window && event.data === 'dmxNextTick' && queue.length) {
                var task = queue.shift();
                task.fn.call(task.context);
            }
        });

        dmx.nextTick = function(fn, context) {
            queue.push({ fn: fn, context: context });
            window.postMessage('dmxNextTick', '*');
        };
    })();
} else {
    dmx.nextTick = function(fn, context) {
        window.setTimeout(fn.bind(context), 0);
    };
}

dmx.requestUpdate = function() {
    var updateRequested = false;

    return function() {
        if (!updateRequested) {
            updateRequested = true;

            dmx.nextTick(function() {
                updateRequested = false;
                if (dmx.app) {
                    dmx.app.$update();
                }
            });

            /*
            requestAnimationFrame(function() {
                updateRequested = false;
                if (dmx.app) {
                    dmx.app.$update();
                }
            });
            */
        }
    };
}();

dmx.debounce = function(fn, delay) {
    var timeout;

    return function() {
        var args = Array.prototype.slice.call(arguments);
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            fn.apply(null, args);
        }, delay || 0);
    };
};

dmx.keyCodes = {
    'bs': 8,
    'tab': 9,
    'enter': 13,
    'esc': 27,
    'space': 32,
    'left': 37,
    'up': 38,
    'right': 39,
    'down': 40,
    'delete': 46,

    'backspace': 8,
    'pause': 19,
    'capslock': 20,
    'escape': 27,
    'pageup': 33,
    'pagedown': 34,
    'end': 35,
    'home': 36,
    'arrowleft': 37,
    'arrowup': 38,
    'arrowright': 39,
    'arrowdown': 40,
    'insert': 45,
    'numlock': 144,
    'scrolllock': 145,
    'semicolon': 186,
    'equal': 187,
    'comma': 188,
    'minus': 189,
    'period': 190,
    'slash': 191,
    'backquote': 192,
    'bracketleft': 219,
    'backslash': 220,
    'bracketright': 221,
    'quote': 222,

    'numpad0': 96,
    'numpad1': 97,
    'numpad2': 98,
    'numpad3': 99,
    'numpad4': 100,
    'numpad5': 101,
    'numpad6': 102,
    'numpad7': 103,
    'numpad8': 104,
    'numpad9': 105,
    'numpadmultiply': 106,
    'numpadadd': 107,
    'numpadsubstract': 109,
    'numpaddivide': 111,

    'f1': 112,
    'f2': 113,
    'f3': 114,
    'f4': 115,
    'f5': 116,
    'f6': 117,
    'f7': 118,
    'f8': 119,
    'f9': 120,
    'f10': 121,
    'f11': 122,
    'f12': 123,

    'digit0': 48,
    'digit1': 49,
    'digit2': 50,
    'digit3': 51,
    'digit4': 52,
    'digit5': 53,
    'digit6': 54,
    'digit7': 55,
    'digit8': 56,
    'digit9': 57,

    'keya': 65,
    'keyb': 66,
    'keyc': 67,
    'keyd': 68,
    'keye': 69,
    'keyf': 70,
    'keyg': 71,
    'keyh': 72,
    'keyi': 73,
    'keyj': 74,
    'keyk': 75,
    'keyl': 76,
    'keym': 77,
    'keyn': 78,
    'keyo': 79,
    'keyp': 80,
    'keyq': 81,
    'keyr': 82,
    'keys': 83,
    'keyt': 84,
    'keyu': 85,
    'keyv': 86,
    'keyw': 87,
    'keyx': 88,
    'keyy': 89,
    'keyz': 90
};

dmx.eventListener = function(target, eventType, handler, modifiers) {
    var timeout, listener = function(event) {
        if (modifiers.self && event.target !== event.currentTarget) return;
        if (modifiers.ctrl && !event.ctrlKey) return;
        if (modifiers.alt && !event.altKey) return;
        if (modifiers.shift && !event.shiftKey) return;
        if (modifiers.meta && !event.metaKey) return;

        if ((event.originalEvent || event) instanceof MouseEvent) {
            if (modifiers.button != null && event.button != (parseInt(modifiers.button, 10) || 0)) return;
        }

        if ((event.originalEvent || event) instanceof KeyboardEvent) {
            var keys = [];

            Object.keys(modifiers).forEach(function(key) {
                var keyVal = parseInt(key, 10);

                if (keyVal) {
                    keys.push(keyVal);
                } else if (dmx.keyCodes[key]) {
                    keys.push(dmx.keyCodes[key]);
                }
            });

            for (var i = 0; i < keys.length; i++) {
                if (event.which !== keys[i]) return;
            }
        }

        if (modifiers.stop) event.stopPropagation();
        if (modifiers.prevent) event.preventDefault();
        
        if (event.originalEvent) event = event.originalEvent;

        if (!event.$data) event.$data = {};

        if (event instanceof MouseEvent) {
            event.$data.altKey = event.altKey;
            event.$data.ctrlKey = event.ctrlKey;
            event.$data.metaKey = event.metaKey;
            event.$data.shiftKey = event.shiftKey;
            event.$data.pageX = event.pageX;
            event.$data.pageY = event.pageY;
            event.$data.x = event.x || event.clientX;
            event.$data.y = event.y || event.clientY;
        }

        if (event instanceof KeyboardEvent) {
            event.$data.altKey = event.altKey;
            event.$data.ctrlKey = event.ctrlKey;
            event.$data.metaKey = event.metaKey;
            event.$data.shiftKey = event.shiftKey;
            event.$data.location = event.location;
            event.$data.repeat = event.repeat;
            event.$data.code = event.code;
            event.$data.key = event.key;
        }

        if (modifiers.debounce) {
            clearTimeout(timeout);
            timeout = setTimeout(handler.bind(this, event), parseInt(modifiers.debounce, 10) || 0);
        } else {
            return handler.call(this, event);
        }
    };

    modifiers = modifiers || {};

    if (window.jQuery && !modifiers.capture) {
        jQuery(target).on(eventType.replace(/-/g, '.'), listener);
    } else {
        target.addEventListener(eventType, listener, !!modifiers.capture);
    }
};

dmx.createClass = function(proto, parentClass) {
    var Cls = function() {
        if (proto.constructor) {
            proto.constructor.apply(this, arguments);
        }
    };

    if (parentClass && parentClass.prototype) {
        Cls.prototype = Object.create(parentClass.prototype);
    }

    Object.assign(Cls.prototype, proto);

    Cls.prototype.constructor = Cls;

    return Cls;
};

dmx.Config = function(config) {
    Object.assign(dmx.config, config);
};

dmx.Component = function(tag, proto) {
    if (proto) {
        var parentClass = dmx.Component(proto.extends) || dmx.BaseComponent; //dmx.__components[proto.extends ? proto.extends : 'base'];

        //if (proto.extends !== tag) {
            //parentClass = dmx.Components(extends);

            proto.initialData = Object.assign({}, parentClass.prototype.initialData, proto.initialData);
            proto.attributes = Object.assign({}, parentClass.prototype.attributes, proto.attributes);
            proto.methods = Object.assign({}, parentClass.prototype.methods, proto.methods);
            proto.events = Object.assign({}, parentClass.prototype.events, proto.events);

            if (!proto.hasOwnProperty('constructor')) {
                proto.constructor = function(node, parent) {
                    parentClass.call(this, node, parent);
                };
            }
        //}

        proto.type = tag;

        var Component = dmx.createClass(proto, parentClass);
        Component.extends = proto.extends;

        dmx.__components[tag] = Component;
    }

    return dmx.__components[tag];
};

dmx.Attribute = function(name, hook, fn) {
    if (!dmx.__attributes[hook]) {
        dmx.__attributes[hook] = {};
    }
    dmx.__attributes[hook][name] = fn;
};

dmx.Formatters = function(type, o) {
    if (!dmx.__formatters[type]) {
        dmx.__formatters[type] = {};
    }
    for (var name in o) {
        dmx.__formatters[type][name] = o[name];
    }
};

dmx.Formatter = function(type, name, fn) {
    if (!dmx.__formatters[type]) {
        dmx.__formatters[type] = {};
    }
    dmx.__formatters[type][name] = fn;
};

dmx.Adapter = function(type, name, fn) {
    if (!dmx.__adapters[type]) {
        dmx.__adapters[type] = {};
    }

    if (fn) {
        dmx.__adapters[type][name] = fn;
    }

    return dmx.__adapters[type][name];
};

dmx.Actions = function(actions) {
    for (var name in actions) {
        dmx.__actions[name] = actions[name];
    }
}

dmx.Action = function(name, action) {
    dmx.__actions[name] = action;
};
